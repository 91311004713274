import React, { Component } from 'react';
import { useParams, useNavigate ,withRouter} from 'react-router-dom';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import axios from 'axios';
import { apiUrl} from'../Config/Config';
import "react-datepicker/dist/react-datepicker.css";

// const withRouter = WrappedComponent => props => {
// 	const params = useParams();
// 	const  navigate = useNavigate();
// 	// etc... other react-router-dom v6 hooks
  
// 	return (
// 	  <WrappedComponent
// 		{...props}
// 		params={params}
// 		navigate ={navigate}
// 		// etc...
// 	  />
// 	);
//   };
	
class Add extends Component {

	constructor(props)
	{
		// CheckAuth();
		super();	
		this.state = {
			title:'',
			selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'active'	,
			selectedCateOption: '',
			selectedCatevalue:'',		
		};

		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleChange = this.handleChange.bind(this);

    }

	componentDidMount() {
	}

	handleChange = (r,selOption) => {       
		if(r == 'status'){
			this.setState({selectedOption:selOption})
			this.setState({  selectedvalue : selOption.value});
		}else if(r == 'cate'){			
			this.setState({selectedCateOption:selOption})
			this.setState({  selectedCatevalue : selOption.value});
		}
    };

   handleInputChange(event) {
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });

  }
 
  
  handleSubmit = () => { 
	if(this.validateForm()){
	this.setState({Loading:true});
	const formdata = this.state;
	var qs = require('qs');
	var status, cate  = '';


	if(formdata.selectedvalue === ''){
	status =formdata.selectedOption.value;
	}else{
	status = formdata.selectedvalue;
	}

	if(formdata.selectedCatevalue === '' && formdata.selectedCatevalue!== 0){
		cate =formdata.selectedCateOption.value;
	}else{
		cate = formdata.selectedCatevalue;
	}
	var postObject ={
		admin_id: localStorage.getItem('admin_id'),
		title : formdata.title,
		cate_id:cate,
		status: status
	}

	let formData = new FormData();
	for(let k in postObject) {
		formData.append(k, postObject[k]);
	}
	axios.post(apiUrl+"guidesubcategory/add",formData).then(res => {
		if(res.data.status === "success"){
				$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
				setTimeout(
				function() {
					$('.success_message').html('');
					
					this.props.history.push('/GuideSubCategory');
				}
				.bind(this),
				3000
				);
			}else{
				$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
				setTimeout(
				function() {
					$('.success_message').html('');
				}
				.bind(this),
				3000
				);
				this.setState({Loading:false});
			}
		});
		
	}
  }

  validateForm() {
	const {title} = this.state;

	let errors = {};
	  let formIsValid = true;

	  if(!title){
		formIsValid = false;
		$('.errortitle').html('<span class="errorspan">Please fill the field</span>');
	}else{
		
		$('.errortitle').html('');
	}
		
	this.setState({
		errors: errors
	});
	return formIsValid;
}



  render() {
	const {selectedOption,selectedCateOption} = this.state;

	
    return (
      <div className="wrapper"> 
 
	  <Header />
      <MenuSidebar currentpage="GuideSubCategory" />  

	<div id="location-error"></div>
	<div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div>
				<a class="primary-btn" href="/GuideSubCategory">Back</a>
			</div>
			<div className="title">
				<h4>Add Guide Sub Category</h4>				
			</div>
			 <form className="edit"  onSubmit={ e => { this.handleSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
				
			<div className="form-left">
				<div className="form-group">					
					<label>Category<span class="required" style={{ color: "red" }} > * </span> :</label>
					<Select 
					value={selectedCateOption?selectedCateOption:""}
					options={lang.common.guidecategories_option} 
					onChange={this.handleChange.bind(this, 'cate')}
					/>
					<div className="errorcate"></div>
	           </div>
			   <div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} > * </span> :</label>
					<Select 
					name="status"
					value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
					options={lang.common.status_option} 
					isSearchable = {false}
					onChange={this.handleChange.bind(this, 'status')}
					/>
	           </div> 
				
			</div>
                  
			<div className="form-right">
				<div className="form-group">
					<label>Sub Category<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="title" onChange={this.handleInputChange} className="form-control" value={this.state.title}/>
					<div className="errortitle"></div>
				</div>
				
			  
             

			</div>	
			</div>		
			<div className="btn-group export">	
				<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true?
                                        <span>Adding</span>:<span>Submit</span>
                                        }
			   </button>
			 
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


export default (withRouter(Add));