import React, { Component } from 'react';
import { useParams, useNavigate ,withRouter} from 'react-router-dom';


import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import axios from 'axios';
import { apiUrl} from'../Config/Config';
// import { CKEditor } from '@ckeditor/ckeditor5-react';
import CKEditor from 'ckeditor4-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { PageTitle, CheckAuth, scrollToTopValidate, scrollToTop } from "../Helpers/SettingHelper";

// const withRouter = WrappedComponent => props => {
// 	const params = useParams();
// 	const  navigate = useNavigate();
// 	// etc... other react-router-dom v6 hooks

// 	return (
// 		<WrappedComponent
// 		{...props}
// 		params={params}
// 		navigate ={navigate}
// 		// etc...
// 		/>
// 	);
// };
class Add extends Component {

	constructor(props)
	{
		// CheckAuth();
		super();	
		this.state = {			
			title:'',
			description:'',
			selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'active',
			image_name: [],
			image_preview: [],
			image:[],
			icon_preview : '',
			image_fileExtension:[],
			allImgArray : [],	
			selectedCateOption: '',		
			selectedCatevalue:'',
			selectedSubCateOption: '',		
			selectedSubCatevalue:'',

			user_type:{ value: 'user', label: 'Merchant'},
			user_type_value:'user',
			module_type:'',
			module_type_value:'',

			ad_link:'',			
			ad_button_text:'',	
			selectedLinkOption:"",
			selectedLinkvalue:"",
		};

		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.onEditorDescription = this.onEditorDescription.bind(this);

    }
	  
	componentDidMount() {
		//this.subCateFunc();
	}
	// handleChange = selectedOption => {
    //     this.setState({selectedOption})
    //     this.setState({  selectedvalue : selectedOption.value});
   	// };
	subCateFunc = (cate_id=null) => {    
		var qs = require('qs'); 
		var postObject = {
			cate_id: cate_id,			
		};
		let catelists = [];
		axios.post(apiUrl+"guidesubcategory/subcatedetail",qs.stringify(postObject)).then(res => {
			// if(res.data.status == "success"){					
			// 	let catedetail = res.data.catedetail;
			// 	this.setState({selectedCateOption:{value: catedetail['id'], label: catedetail['cate_name']}});					
			// }
			if(res.data.status == "success"){
				let i=0;
				let catelist = res.data.catelist;
				
				for(i; i<catelist.length; i++){
					catelists[i] = { value: catelist[i]['id'], label: catelist[i]['sub_cate_name'] };
				}
				//console.log(catelists, '---catelists');
				this.setState({selectedSubCateOption:catelists});	
			}      
		});
	}   
	handleChange = (r,selOption) => {        
		if(r == 'status'){
			this.setState({selectedOption:selOption})
			this.setState({  selectedvalue : selOption.value});
		}
		else if(r == 'cate'){			
			this.subCateFunc(selOption.value);
			this.setState({selectedCateOption:selOption})
			this.setState({  selectedCatevalue : selOption.value});
		}
		else if(r == 'subcate'){		
			
			this.setState({selectedSubCateOption:selOption})
			this.setState({  selectedSubCatevalue : selOption.value});
		}
		else if(r == 'inputtype'){			
			this.setState({selectedLinkOption:selOption})
			this.setState({  selectedLinkvalue : selOption.value});
			
		}
    };

   handleInputChange(event) {
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });

		if(name === 'apply_on_maintab'){
			this.setState({apply_on_maintab: !this.state.apply_on_maintab});
		} 
		if(name === 'apply_on_innertab'){
			  this.setState({apply_on_innertab: !this.state.apply_on_innertab});
		  } 	

  }

  validateForm() {
	//const {admin_company_name,admin_fname} = this.state;
	const {title,module_type_value , selectedCateOption, selectedSubCateOption, selectedSubCatevalue}  = this.state;
	var description = this.state.description;
	let errors = {};
	  let formIsValid = true;
		
	
	if (!title) {
		formIsValid = false;
		$('.errortab_title').html('<span class="errorspan">Please fill the field</span>');
	}else if(title){
		$('.errortab_title').html('');
	}

	if (!description) {
		formIsValid = false;
		$('.errordescription').html('<span class="errorspan">Please fill the field</span>');
	}else if(description){
		$('.errordescription').html('');
	}
	// if (!module_type_value) {
	// 	errors++;
	// 	$('.errormodule').html('<span class="errorspan">Please select Module</span>');
	// }else if(module_type_value){
	// 	$('.errormodule').html('');
	// }
	if(!selectedCateOption){
		errors++;
		$('.errorcate').html('<span class="errorspan">Please fill the field</span>');
	}else{
		
		$('.errorcate').html('');
	}

	if(!selectedSubCatevalue){
		errors++;
		$('.errorsubcate').html('<span class="errorspan">Please fill the field</span>');
	}else{
		
		$('.errorsubcate').html('');
	}

	this.setState({
		errors: errors
	});
	return formIsValid;
}

  handleSubmit = () => {
	if(this.validateForm()){ 
	const formdata = this.state;
	var qs = require('qs');
	var status , modue_typeIs, user_typeIs, cate, subcate = '';
	

	if(formdata.selectedvalue === ''){
	status =formdata.selectedOption.value;
	}else{
	status = formdata.selectedvalue;
	}
	if(formdata.module_type_value === '' && formdata.module_type_value!== 0){
		modue_typeIs =formdata.module_type.value;
	}else{
		modue_typeIs = formdata.module_type_value;
	}
	// if(formdata.user_type_value === '' && formdata.user_type_value!== 0){
	// 	user_typeIs =formdata.user_type.value;
	// }else{
	// 	user_typeIs = formdata.user_type_value;
	// }
	if(formdata.selectedCatevalue === '' && formdata.selectedCatevalue!== 0){
		cate =formdata.selectedCateOption.value;
	}else{
		cate = formdata.selectedCatevalue;
	}
	
	if(formdata.selectedSubCatevalue === '' && formdata.selectedSubCatevalue!== 0){
		subcate =formdata.selectedSubCateOption.value;
	}else{
		subcate = formdata.selectedSubCatevalue;
	}
	
	


	var postObject ={
		admin_id: localStorage.getItem('admin_id'),	
		title : formdata.title,
		description: formdata.description,		
		// modue_typeIs      :modue_typeIs,					
		// user_typeIs      :user_typeIs,
		ad_link:formdata.ad_link,	
		ad_button_text:formdata.ad_button_text,			
		cate_id:cate,									
		sub_cate_id      :subcate,		
		modue_typeIs      :modue_typeIs,						
		link_type      :formdata.selectedLinkvalue,		
		status: status		
	}
	

	let formData = new FormData();
	for(let k in postObject) {
		formData.append(k, postObject[k]);
	}

	var imgdata = formdata.allImgArray;
	for (var i in imgdata) {
		formData.append('images[]',imgdata[i])
	}


	// var icondata = formdata.icon;
	// formData.append('file',icondata)

	axios.post(apiUrl+"guide/add",formData).then(res => {
		if(res.data.status === "success"){
			$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
			setTimeout(
				function() {
					$('.success_message').html('');					
					this.props.history.push('/Guide');
				}
				.bind(this),
				3000
			);						
		}else{
			$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
			
			setTimeout(
			function() {
				$('.success_message').html('');
			}
			.bind(this),
			3000
			);
		}
	});
	}
		
	}

	onChangeHandler=event=>{
		let reader = new FileReader();
		const file = event.target.files[0];
		const extension = file.name.split('.').pop();
		reader.onloadend = () => {
		  this.setState({
			icon_name: file.name,
			icon_preview: reader.result,
			icon: file,
			icon_fileExtension:extension,
			
		  });
		};
		reader.readAsDataURL(file);
		
	  } 


	  onChangeMultipleHandler= e => {
		this.fileObj = [];
		this.fileNameArray = this.state.image_name;
		this.fileArray = this.state.image;
		this.extensionArray = this.state.image_fileExtension;
		this.image_preview = this.state.image_preview;
		this.allImgArray = this.state.allImgArray;
		this.fileObj.push(e.target.files);
		for (let i = 0; i < this.fileObj[0].length; i++) {
			this.fileNameArray.push(this.fileObj[0][i].name)
			this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
			this.allImgArray.push(this.fileObj[0][i])
			this.extensionArray.push(this.fileObj[0][i].name.split('.').pop());
			
		}
		
		this.setState({
			image_name: this.fileNameArray,
			image_preview: this.image_preview,
			image: this.fileArray,
			image_fileExtension:this.extensionArray,
			
		  });
		
	  }
	  
	removeImagebyindex = (indexvalue) => {
		var list = this.state.image;
		var allImgArray = this.state.allImgArray;
		
		this.imageArray = [];
		this.fileArray = [];
		list.splice(indexvalue, 1);
		allImgArray.splice(indexvalue,1)

	
		this.setState({ image: list , allImgArray : allImgArray })
		
	}

	handlefieldTypeChange(event) {   
		this.setState({module_type:[event],module_type_value:event.value})
	}
	handleadsfieldTypeChange(event) {   
		this.setState({module_type:'',module_type_value:''})
		this.setState({user_type:[event],user_type_value:event.value})
	}
	onEditorDescription( evt ) {
    	var callfunc = this;
    	setTimeout(function () {
			callfunc.setState( {
				description: evt.editor.getData()
			});
		},1000);     
    }

  render() {
	  const {selectedOption, selectedCateOption,module_type_value, selectedSubCateOption, selectedLinkOption} = this.state;	 
	var imgThumblist ;
	let indexlength =  this.state.image.length;
	if(this.state.image.length > 0){
		
		imgThumblist =  this.state.image.map((url,index) => (
				<li className="thumb" key={indexlength+index}  >
					<span><img src={url}  alt="..." /></span>
					<a href="javascript:void(0);" onClick={this.removeImagebyindex.bind(this,index)}>X</a>
				</li>
			));
		}
  

	var	preview = (this.state.icon_preview) ? 
	<img className="img_class" src={this.state.icon_preview} alt="" /> :'';

    return (
      <div className="wrapper"> 
 
	  <Header />
      <MenuSidebar currentpage="guide" />  

	<div id="location-error"></div>
	<div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div>
				<a class="primary-btn" href="/guide">Back</a>
			</div>
			<div className="title">
				<h4>Add Guide</h4>	
			</div>
			 <form className="edit"  onSubmit={ e => { this.handleSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
				
				<div className="form-group">					
					<label>Category<span class="required" style={{ color: "red" }} > * </span> :</label>
					<Select 
					value={selectedCateOption?selectedCateOption:""}
					options={lang.common.guidecategories_option} 
					onChange={this.handleChange.bind(this, 'cate')}
					/>
					<div className="errorcate"></div>
	           </div>

				<div className="form-group">
					<label>Title<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="title" onChange={this.handleInputChange} className="form-control" value={this.state.title}/>
					<div className="errortab_name"></div>
				</div>

				{/* <div className="form-group">
					<label>Image (jpeg,jpg,png,svg)</label>
					<div className="choose-file">
					{this.state.isHidden && <div className="image_success"></div>}
					<span className="profile_btn"><input type="file" name="file" onChange={this.onChangeHandler} /></span>
					</div>
					{preview}
					
					<div className="errorimage"></div>  
				</div> 	 */}
				<div className="form-group">
					<label>Button Text:</label>
						<input type="text" name="ad_button_text" onChange={this.handleInputChange} className="form-control" value={this.state.ad_button_text} />
					<div className="errorad_button_text"></div>
				</div>

				<div className="form-group">
					<label>Images (jpeg,jpg,png,svg)</label>
					<div className="choose-file">
					{this.state.isHidden && <div className="image_success"></div>}
					<span className="profile_btn"><input type="file" name="images" onChange={this.onChangeMultipleHandler} multiple/></span>
					</div>
					<ul className='listing-thump d-flex gap-3 flex-wrap'>
					
					{imgThumblist}
					</ul>
					<div className="errorimage"></div>  
				</div> 			

			</div>
                  
			<div className="form-right">
				<div className="form-group">					
					<label>Sub Category<span class="required" style={{ color: "red" }} > * </span> :</label>
					<Select 
					value={selectedSubCateOption?selectedSubCateOption:""}
					options={selectedSubCateOption} 
					onChange={this.handleChange.bind(this, 'subcate')}
					/>
					<div className="errorsubcate"></div>
	           </div>
			    
			   <div className="form-group">
					<label>Link Type</label>
					<Select
						value={selectedLinkOption?selectedLinkOption:""}
						options={lang.common.linktype} 
						onChange={this.handleChange.bind(this, 'inputtype')}
					/>
					<div className="errorlinktype"></div>
					</div>	
				{this.state.selectedLinkvalue=='internal'?
				<div className="form-group module">
					<label>Module: </label>
					<Select 
					value={this.state.module_type!=''?this.state.module_type:'Choose'}
					options={lang.common.guide_field_options} 
					onChange={this.handlefieldTypeChange.bind(this)}
					placeholder= 'Module'
					isSearchable={false}
					/>
					<div className="errormodule"></div>
				</div>:''}
				{this.state.selectedLinkvalue=='external'?
				<div className="form-group">
					<label>Link Url: </label>
					<input type="text" name="ad_link" onChange={this.handleInputChange} className="form-group" value={this.state.ad_link} />
					<div className="errorad_link"></div>
				</div>:''}

				<div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} > * </span> :</label>
                    <Select 
                    name="status"
                    value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                    options={lang.common.status_option} 
                    isSearchable = {false}
					onChange={this.handleChange.bind(this, 'status')}
                    />
	           </div>
				<div className="form-group">					
					<label>Description<span class="required" style={{ color: "red" }} > * </span> : </label>
						{/* <textarea type="text" name='description' onChange={this.handleInputChange} className='form-control' value={this.state.description} /> */}						
						 <CKEditor
							// editor={ ClassicEditor }
							data={this.state.description}
							onReady={ editor => {
								// You can store the "editor" and use when it is needed.
								console.log( 'Editor is ready to use!', editor );
								editor.ui.view.editable.element.style.minHeight = "300px";
							} }
							name="description"
							// onChange={this.handleInputChange}
							// onChange={ ( event, editor ) => {
							// 	const data = editor.editor.getData();
							// 	this.setState({description: data});
							// 	editor.ui.view.editable.element.style.minHeight = "300px";
								
							// } }
						onChange={this.onEditorDescription}
						/> 
					<div className="errordescription"></div>
				</div>
                
			</div>	
			</div>		
			<div className="btn-group export">	
				<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" >
									{/* <span className="load-	data"></span>  */}
									Submit
			   </button>
			 
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


export default (withRouter(Add));