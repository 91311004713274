import React, { Component } from 'react';
import { withRouter,Link  } from 'react-router-dom';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth } from "../Helpers/SettingHelper";
import { apiUrl,tapURL,cmsURL} from'../Config/Config';
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import CKEditor from 'ckeditor4-react';

class Edit extends Component {
	fileObj = [];
    fileArray = [];
    imageArray = [];
	constructor(props)
	{
		CheckAuth();
		super(props);	
		const tabId = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;

		this.state = {
            tabId: tabId,
			offer_title:'',
			description:'',
			title:'',
			content:'',
			Loading:false,
			selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'',
			selectedtype : '',
			selectedtypeValue : '',
			editid:0,
			image_preview:'',
			image_name:'',
			image:'',
			tabFolder:'',
			old_image:'',
			fileExtension:'',
			showCategoryDropdown:false,
			category_id:'',
			categorynamelist: [],
			selectedCategory:'',
			button_name:'',
			short_description:'',
			cmsgalleries: '',
			selectid:'',
			imagepath:'',
			selected_image:'',
			tab_image:'',
			agreedescription:'',
			button_text:'Book',
			productnamelist:'',		
			selectedLinkOption:'',
			selectedproductOption	:"",
			module_type:'',
			module_type_value:'',	
			selectedproductvalue:'',
			selectedOptiondata:'',
			ad_link:'',
		};
		this.onEditorChange = this.onEditorChange.bind( this );
		this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this);
		this.removeImagebyindex = this.removeImagebyindex.bind(this);
		this.onEditorChangedrescription = this.onEditorChangedrescription.bind(this);
		this.onEditorChangeshort_description = this.onEditorChangeshort_description.bind(this);
		
		// this.props.getCategoryDetailList(tabId);
		var qs = require('qs');
		axios.get(apiUrl+'cmsmanagement/productlist?admin_id='+localStorage.getItem("admin_id"))	
		.then(res => {
			if(res.data.status == 'success'){	
				this.setState({		
					productnamelist: [{value: '', label: 'Select Product'}].concat(res.data.productnamelist)
				});
			}
		});

		var catid = this.state.tabId;
		var postObject = {
			id : this.state.tabId,
			admin_id : localStorage.getItem("admin_id"),
		};
		axios.post(apiUrl+'cmsmanagement/listdetail',qs.stringify(postObject))
		.then(res => {
			this.setState({offer_title:res.data.cmslist.name, short_description:res.data.cmslist.short_description,tabdescriptions_name:res.data.cmslist.cmsdescriptions,description: res.data.cmslist.description,category_id:res.data.category,description:res.data.cmslist.description,title:res.data.cmslist.title});
			this.setState({ selectedCategory : res.data.categoryDetails})
			this.setState({ cmsgalleries: res.data.cmsimageslist,button_text:res.data.cmslist.button_text,}, () => {
			});
			const formdata = res.data.cmslist;	
			if(formdata.module_name){						
				this.setState({module_type_value : formdata.module_name})
				this.setState({module_type : [{label:lang.Capitalize(formdata.module_name),value:formdata.module_name}]});
			}

			if(formdata.link_type=='internal'){
				this.setState({selectedLinkOption:{value: 'internal', label: 'Internal link'}});
			}else if(formdata.link_type=='external'){
				this.setState({ad_link:formdata.link,selectedLinkOption:{value: 'external', label: 'External link'}});
			}else if(formdata.link_type=='product'){
				this.setState({selectedLinkOption:{ value: 'product', label: 'Product' }});					
			}

			if(formdata.product_id!=null&&formdata.product_id!='')
				{
					this.setState({  selectedproductvalue :formdata.product_id});
					if(res.data.selectedproduct!=undefined&&res.data.selectedproduct!='')
					{
						this.setState({  selectedproductOption:res.data.selectedproduct});
					}
				}

			if(res.data.cmslist.status === 'A'){
				this.setState({selectedOption:{value: 'active', label: 'Active'}});
			}else{
			this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});
			}
			
			
			this.setState({editid:1,image_preview:res.data.cmslist.image,tabFolder:res.data.tabfolder,old_image:res.data.cmslist.image})
		}); 
		
		this.handleInputChange = this.handleInputChange.bind(this);
	    this.handleChange = this.handleChange.bind(this);

    }
	uploadMultipleFiles = (e) =>{
		this.fileObj = [];
	
			this.fileObj.push(e.target.files)
			for (let i = 0; i < this.fileObj[0].length; i++) {
				this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
				//this.fileArray.push(this.fileObj[0][i]
				this.imageArray.push(this.fileObj[0][i])
	
			}
			
			this.setState({ tab_image: this.imageArray,galleryimg:2,selectedimages:[] })		
		}
		removeImagebyindex = (indexvalue) => {
		   
			var list = [...this.imageArray];
			this.imageArray = [];
			this.fileArray = [];
			list.splice(indexvalue, 1);
		   
			for (let i = 0; i < list.length; i++) {
			  this.imageArray.push(list[i]);
			  this.fileArray.push(URL.createObjectURL(list[i]));
			}
		  
			  this.setState({ tab_image: this.imageArray })	
	  }
	  handleChange = (r,selOption) => {
		if(r == 'status'){
			this.setState({selectedOption:selOption})
			this.setState({  selectedvalue : selOption.value});
		}else if(r == 'product'){		

			this.setState({selectedproductOption:selOption})
			this.setState({  selectedproductvalue : selOption.value});
		}else if(r == 'inputtype'){			
			this.setState({selectedLinkOption:selOption})
			this.setState({  selectedLinkvalue : selOption.value});
			$('.errorlinktype').html('');
		}
	  }
	onEditorChange( evt ) {
		console.log(evt, 'CKE')
		setTimeout(function () {
		this.setState( {
			short_description: evt
		} );
		}.bind(this),1000);
	}
   handleInputChange(event) {
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
      if(name === 'tab_trending'){
      	this.setState({tab_trending_checked: !this.state.tab_trending_checked});
      }  		
    }

	componentDidMount() {
		document.title = PageTitle('Offer Edit');
		var admin_id = localStorage.getItem('admin_id');		
		axios.get(apiUrl+'offermanagement/productlist?admin_id='+admin_id)
		.then(res => {
			if(res.data.status == 'success'){	
				this.setState({			
					productnamelist: [{value: '', label: 'Select Product'}].concat(res.data.productnamelist)
				});	
			}else{
			}
		});
    }

	onChangeHandler=event=>{
		let reader = new FileReader();
		const file = event.target.files[0];
		const extension = file.name.split('.').pop();
		reader.onloadend = () => {
		  this.setState({
			image_name: file.name,
			image_preview: reader.result,
			image: file,
			fileExtension:extension,editid:0
		  });
		};
		reader.readAsDataURL(file);
	}
        handleFormSubmit = () => {
			if(this.validateForm()){ 
				this.setState({Loading:true});				
				const formPayload = this.state;
				var qs = require('qs');
				var status,modue_typeIs,product_id = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}

				if(formPayload.module_type_value === '' && formPayload.module_type_value!== 0){
					modue_typeIs =formPayload.module_type.value;	
				}else{
					modue_typeIs = formPayload.module_type_value;
				}			
				if(formPayload.selectedLinkOption.value == 'product'){	
					if(formPayload.selectedproductvalue != '' && formPayload.selectedproductvalue!== 0){
						product_id =formPayload.selectedproductvalue;
					}
				}

				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					offer_title: formPayload.offer_title,
					cms_description: formPayload.description,
					short_description: formPayload.short_description,
					status:  status,
					editid:  this.state.editid,
					old_image:  this.state.old_image,
					image: this.state.editid===0 ? formPayload.image:'',
					id: formPayload.tabId,
					button_text:formPayload.button_text,
					
					link_type:formPayload.selectedLinkOption.value,		
					link:formPayload.ad_link,		
					modue_typeIs      :modue_typeIs,	
					product_id      :product_id,
				};
				if (formPayload.selectedtypeValue !== 'product') {
					postObject.category = null;
				} else {
					// If content_type is 'product', assign the category value
					postObject.category = formPayload.categoryvalue;
				}
				
				let formData = new FormData();
				for(let k in postObject) {
				formData.append(k, postObject[k]);
				}		
				var filesdata = this.state.tab_image;
						var galleryimg = this.state.galleryimg;
						if(galleryimg==2){
						for (var i in filesdata) {
							formData.append('multiple_images[]',filesdata[i])
						}
					}         
				axios.post(apiUrl+"cmsmanagement/edit",formData, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}).
				
				then(res => {
					if(res.data.status === "success"){	
						this.setState({Loading:false});
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
							setTimeout(
							function() {
								$('.success_message').html('');
								// this.props.history.push('offers');
							}
							.bind(this),
							3000
							);
					}else{
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');						
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}
				});
			}
	}

	validateForm() {

		const {offer_title,title,description,selectedtypeValue,image ,short_description ,button_text,selectedLinkOption,ad_link,selectedproductvalue,module_type_value} = this.state;
		let errors = {};
      	let formIsValid = true;
		
		if (!button_text) {
			formIsValid = false;
			$('.errorbutton_text').html('<span class="errorspan">Please fill the field</span>');
		}else if(button_text){			
			$('.errorbutton_text').html('');
		}
		  if (!offer_title) {
			formIsValid = false;
			$('.erroroffer_title').html('<span class="errorspan">Please fill the field</span>');
		}else if(offer_title){
			if (/^[a-zA-Z\s]*$/.test(offer_title)) {
			$('.erroroffer_title').html('');
			}else{
				formIsValid = false;
				$('.erroroffer_title').html('Please enter valid tab name');
			}
		}
		
		if (!short_description) {
			formIsValid = false;
			$('.errorshort_description').html('<span class="errorspan">Please fill the short descrtions</span>');
		}else if(short_description){
			$('.errorshort_description').html('');
		}
		if (!description) {
			formIsValid = false;
			$('.errordescription').html('<span class="errorspan">Please fill the field</span>');
		}else if(description){
			$('.errordescription').html('');
		}
		if(selectedLinkOption.value == 'internal'){
			if (!module_type_value) {
				errors++;
				$('.errormodule').html('<span class="errorspan">Please select Module</span>');
			}else if(module_type_value){
				$('.errormodule').html('');
			}
		}
		if(selectedLinkOption.value == 'external'){
			if (!ad_link) {
				errors++;
				$('.errorad_link').html('<span class="errorspan">Please fill the field</span>');
			}else if(ad_link){
				$('.errorad_link').html('');
			}
		}
		if(selectedLinkOption.value == 'product'){
			if (!selectedproductvalue) {	
				errors++;
				$('.erroroffers').html('<span class="errorspan">Please fill the field</span>');
			}else if(selectedproductvalue){		
				$('.erroroffers').html('');
			}
		}		
		this.setState({
			errors: errors
		});
		return formIsValid;
    }
	handleClickSelecetCategory = (event) => {
		const newCategoryId = event.target.value;
		console.log(newCategoryId,'new');
		this.setState({ category_id: newCategoryId });
	};
	
	handleChangeCategory = selectedCategory => {

		this.setState({ selectedCategory});
		this.setState({ categoryvalue : selectedCategory.value });
 	}
	 confirmPopup = (id,imagepath,selected_image,type) => {
		this.setState({selectid: id,imagepath:imagepath,selected_image:selected_image, type: type})	
		$('.confirm-action').addClass('show');		
	}
	deleteimage(){
        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id"),
          gallery_id  :this.state.selectid,
          id  :this.state.tabId,
          imagepath  :this.state.imagepath,
          selected_image  :this.state.selected_image,
		  type: this.state.type
        };
        axios.post(apiUrl+"cmsmanagement/deleteimage",qs.stringify(postObject)).then(res => {      
          	if(res.data.status === 'success'){
				if(this.state.type == 'product'){
					this.setState({productgalleries:res.data.productgalleries})
				}else{
					this.setState({tryoutgalleries:res.data.tryoutgalleries})
				}
				this.setState({ cmsgalleries: res.data.productgalleries }, () => {
					
				});
				$('.success_message').html('<div class="status_sucess"><h3> '+ res.data.message +' </h3></div>');
				setTimeout(function() {
					$('.success_message').html('');   
				}.bind(this),3000);
          	}
        }); 
	}
	Confirmstatus = (confirmstatus) => {
		if(confirmstatus == 'yes'){
		  this.deleteimage();
		  $('.confirm-action').removeClass('show');
		}else{
		  $('.confirm-action').removeClass('show');	
		}  
	}
	handleTypeChange = selectedOption => {
		const selectedTypeValue = selectedOption.value;
		this.setState({ 
			selectedtype: selectedOption,
			selectedtypeValue: selectedOption.value,
			showCategoryDropdown: false,
		  });
		
    };  
	onEditorChangedrescription( evt ) {
		var callfunc = this;
		setTimeout(function () {
			callfunc.setState( {
				description: evt.editor.getData()
			});
		},1000);     
	}
	onEditorChangeshort_description( evt ) {
		var callfunc = this;
		setTimeout(function () {
			callfunc.setState( {
				short_description: evt.editor.getData()
			});
		},1000);     
	}
	handlefieldTypeChange(event) {   
		this.setState({module_type:[event],module_type_value:event.value})
	}
  render() {

  	  const {selectedOption,selectedtype ,categorynamelist,selectedLinkOption,selectedproductOption,selectedOptiondata,module_type} = this.state;

		let imageslist = [];

	  let indexlength =  imageslist.length;

	const gallerylocal =  this.fileArray && this.fileArray.map((url,index) => (
		<li className="thumb" key={indexlength+index}  >
			<span><img src={url}  alt="..." /></span>
			<a href="javascript:void(0);" onClick={this.removeImagebyindex.bind(this,index)}>X</a>
		</li>
	))
	if(this.state.editid==1){
		var loaderimg = cmsURL + '/'+this.state.image_preview;
		
		var preview = <img className="img_class" src={loaderimg} alt="" />;
	}else {
	
		
		
		var	preview = <img className="img_class" src={this.state.image_preview} alt="" />;

	}
	

	
  	
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="cms" />  
      <div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="content-wrapper">
				<a href="/offers" >Back</a>   
			</div>
			<div className="title">
				<h4>Edit Details</h4>
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} encType="multipart/form-data">
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Page title<span class="required" style={{ color: "red" }} >* </span> : </label>
				    <input type="text" name="offer_title" onChange={this.handleInputChange} className="form-control" value={this.state.offer_title} />
					<div className="erroroffer_title"></div>
				</div>
				
				<div className="form-group">
					<label>Button text<span class="required" style={{ color: "red" }} >* </span> : </label>
					<input type="text" name="button_text" onChange={this.handleInputChange} className="form-control" value={this.state.button_text} />
					<div className="errorbutton_text"></div>
				</div>
				<div className="form-group">
					<label>Short Description<span class="required" style={{ color: "red" }} >* </span> :</label>
					<CKEditor
						data={this.state.short_description}
						//data={templatecontent}
						onChange={this.onEditorChangeshort_description}
						type="classic"
					/> 
					{/* <MyEditor initialData={this.state.short_description} onEditorChange={this.onEditorChange}></MyEditor> */}
					<div className="errorshort_description"></div>	
				</div>			
				
			   <div className="form-row">
									{/* <div className="form-left"> */}
										{this.state.cmsgalleries!=''?
										<div className="form-group">
											<div className='gallery-main'>
												<label>Image Galleries</label>
												<div className='gallery-container gallery-list d-flex flex-wrap gap-4'>
													{this.state.cmsgalleries?.map((item) => (
													<div className='gallery-inner' key={item.rehab_gallery_id}>
														<img width="100px" height="100px" src={cmsURL+'/'+'cmsimages'+'/'+item.cms_image} alt={`Image ${item.id}`}  />
														 <span onClick={this.confirmPopup.bind(this, item.id,item.cms_image_path,item.cms_image,'tab')} className="fa fa-times cursor-pinter" title="Delete"></span>
													</div>
													))}
												</div>
											</div>
										</div>:''}		
									{/* </div> */}
								</div>
			   <div className="form-group">
					<label>Upload Mulitiple Images (jpeg,jpg,png,svg)<span class="required" style={{ color: "red" }} ></span>:</label>
					<div className="choose-file">
						{this.state.isHidden && <div className="image_success"></div>}
						<span className="profile_btn"><input type="file" name="file" onChange={this.uploadMultipleFiles} multiple /></span>
					</div>
					<ul className="carimagelisting">
						{gallerylocal}
					</ul>
				</div>
			</div>
                  
			<div className="form-right">
				<div className="form-group">
					<label>Link Type<span class="required" style={{ color: "red" }} >*</span></label>
					<Select
						value={selectedLinkOption?selectedLinkOption:""}
							options={lang.common.offer_linktype}
							onChange={this.handleChange.bind(this, 'inputtype')}
							/>
					<div className="errorlinktype"></div>
				</div>
					{selectedLinkOption!=undefined&&<>{selectedLinkOption.value=='internal'&&

				<div className="form-group">

					<label>Module<span class="required" style={{ color: "red" }} >* </span> : </label>

					<Select 

						//value={this.state.module_type!=''?this.state.module_type:'Choose'}

						value={module_type!=''?module_type:'Choose module'}

						options={lang.common.ads_field_options} 

						onChange={this.handlefieldTypeChange.bind(this)}

						placeholder= 'Module'

						isSearchable={false}

					/>

					<div className="errormodule"></div>

				</div>}

				{selectedLinkOption.value=='external'&&

				<div className="form-group">

					<label>Link Url<span class="required" style={{ color: "red" }} >* </span> : </label>

					<input type="text" name="ad_link" onChange={this.handleInputChange} className="form-group" value={this.state.ad_link} />

					<div className="errorad_link"></div>

				</div>}



				{selectedLinkOption.value=='product'?

				<div className="form-group">					

				<label>Products<span class="required" style={{ color: "red" }} > * </span> :</label>

				<Select 

					value={selectedproductOption?selectedproductOption:""}

					options={this.state.productnamelist} 

					onChange={this.handleChange.bind(this, 'product')}

				/>

				<div className="erroroffers"></div>

				</div>:''}</>}
				<div className="form-group">
									<label>Description<span class="required" style={{ color: "red" }} ></span></label>
									
									{/* <CKEditor
										editor={ ClassicEditor }
										data={this.state.description}
										onReady={ editor => {
											
											console.log( 'Editor is ready to use!', editor );
										} }
										onChange={ ( event, editor ) => {
											const data = editor.getData();
											this.setState({description: data});
											console.log( data,"data");
										} }
									/> */}
									<CKEditor
										data={this.state.description}
										//data={templatecontent}
										onChange={this.onEditorChangedrescription}
										type="classic"
									/> 
									<div className="errorshort_description"></div>  
									
								</div>
							<div className="form-group">					
								<label>Status<span class="required" style={{ color: "red" }} >* </span> :</label>
								<Select 
									value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
									options={lang.common.status_option} 
									onChange={this.handleChange.bind(this, 'status')}
								/>
							</div> 
						</div>	
			</div>		



			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true?
                                        <span>updating</span>:<span>Submit</span>
                                        }
                   </button>
			</div>
			</form>
			</div>				
		</div>	
	</div>
			<div className="confirm-action">
					<p>Are you sure! you want to delete this image?</p>
					<a href="javascript://" onClick={this.Confirmstatus.bind(this, "yes")} className="btn">Yes</a>
					<a href="javascript://" onClick={this.Confirmstatus.bind(this, "no")} className="btn">No</a>
				</div>	
    </div>
    );
  }
}


// const mapStateTopProps = (state) => {
//   return {
//     categorydetails: state.categorydetails,
//     categoryedit: state.categoryedit
//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//     getCategoryDetailList: (tabId) =>{
//        dispatch({ type: GET_CATEGORYDETAILLIST,tabId });
//     },
//     getEditCategory: (formPayload) =>{
//        dispatch({ type: GET_EDITCATEGORY, formPayload});
//     }
//   }
// }
export default withRouter(Edit);