import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import { GET_VIEWUSERLIST } from '../../actions';
import { mediaUrl} from'../Config/Config';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";
import moment from 'moment';
class ViewEHR extends Component {

  constructor(props)
  {
    super(props); 
    const userid = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;

    this.state = {      
      userid: userid,
      userlistview:'',
      familtlistview:'',
      pastappointment:'',
      ongoingappts:'',
      futureappts:'',
      activetab: 'userdetail',  
      ShowFamilyDocuments:false,
      FamilyDocuments:'',

    };

    var qs = require('qs');
    var postObject = {             
    id   :userid
    };
    this.props.getViewUserList(qs.stringify(postObject));  
  }

   
   componentDidMount() {
     document.title = PageTitle('User View');
   }

   componentDidUpdate(prevProps,prevState){

   }
   componentWillReceiveProps(Props){
      if(Props.userlistview!==this.state.userlistview){
        if(Props.userlistview[0].status == "success"){
          this.setState({userlistview: Props.userlistview[0].userlistview[0]}) 
          this.setState({familtlistview: Props.userlistview[0].familylist}) 
          this.familylist();
          this.setState({pastappointment: Props.userlistview[0].pastappointment},function(){
            this.pastappointment();
          }) 
          this.setState({ongoingappts: Props.userlistview[0].ongoingappts},function(){
            this.ongoingappts();
          }) 
          this.setState({futureappts: Props.userlistview[0].futureappts},function(){
            this.futureappts();
          }) 
        }
      }
   }
   familylist() {
    var familtlist = this.state.familtlistview;
    if (familtlist != "undefined" && familtlist != null) {
      // if ( == "success"){
        if (Object.keys(familtlist).length > 0) {
          const familylistDetails = familtlist.map(
            (familtlist, Index) => {
              if(familtlist.gender == 'Male'){
                var gen = 'M';
              }else{
                var gen = 'F';
              }
              let sno = Index+1;
              return (
                <tr key={familtlist.id}>
				         <td>{sno}</td>
                 <td>
                  {familtlist.name} ({gen})
                  </td>
                   <td>
                  {familtlist.relationship}
                  </td>
                  <td>
                  {familtlist.age}
                  </td>
                  <td>
                  {familtlist.dob}
                  </td>
                  <td>
                  {familtlist.email}
                  </td>
                  <td>
                  {familtlist.contact_no!== null?familtlist.contact_no:'-'}
                  </td>
                  <td>
                  <a class="" title="View" href="#" onClick={this.ShowFamilyDocuments.bind(this,familtlist.family_member_documents)}>  <i class="ft-eye" aria-hidden="true"></i></a>
                  </td>
                 
                 
                </tr>
              );
            }
          );
          return familylistDetails;
        }
      else {
        return (
          <tr>
            <td colSpan="9" className="v-align-nr text-center">
              No Result
            </td>
          </tr>
        );
      }
    
    }
  }

  pastappointment() {
    var appointments = this.state.pastappointment;
    if (appointments != "undefined" && appointments != null) {
      // if ( == "success"){
        if (Object.keys(appointments).length > 0) {
          const apptDlist = appointments.map(
            (appts, Index) => {
              let sno = Index+1;
              var apptstatus = 'Pending';
              if(appts.paid_status =='booked'){
                var apptstatus = 'Accepted'
              }else  if(appts.paid_status =='paid'){
                var apptstatus = 'Completed'
              }
              return (
                <tr key={Index}>
				         <td>{sno}</td>
                 <td>{appts.appointment_id}</td>
                 <td>{moment(appts.appointment_date).format('MMM DD Y hh:mm A')}</td>
                 <td>
                  {appts.medical_condition}
                  </td>
                  <td>
                  {appts.name}
                  </td>
                
                  <td>
                  {appts.gender}
                  </td>
                 
                  <td>
                  {appts.phone}
                  </td>
                  <td>
                    {apptstatus}
                  </td>
                 
                </tr>
              );
            }
          );
          return apptDlist;
        }
      else {
        return (
          <tr>
            <td colSpan="9" className="v-align-nr text-center">
              No Result
            </td>
          </tr>
        );
      }
    
    }
  }

  ongoingappts() {
    var appointments = this.state.ongoingappts;
    if (appointments != "undefined" && appointments != null) {
      // if ( == "success"){
        if (Object.keys(appointments).length > 0) {
          const apptDlist = appointments.map(
            (appts, Index) => {
              let sno = Index+1;
              var apptstatus = 'Pending';
              if(appts.paid_status =='booked'){
                var apptstatus = 'Accepted'
              }else  if(appts.paid_status =='paid'){
                var apptstatus = 'Completed'
              }
              return (
                <tr key={Index}>
				         <td>{sno}</td>
                 <td>{appts.appointment_id}</td>
                 <td>{moment(appts.appointment_date).format('MMM DD Y hh:mm A')}</td>
                 <td>
                  {appts.medical_condition}
                  </td>
                  <td>
                  {appts.name}
                  </td>
                
                  <td>
                  {appts.gender}
                  </td>
                  <td>
                  {appts.phone}
                  </td>
                  <td>
                    {apptstatus}
                  </td>
                 
                </tr>
              );
            }
          );
          return apptDlist;
        }
      else {
        return (
          <tr>
            <td colSpan="9" className="v-align-nr text-center">
              No Result
            </td>
          </tr>
        );
      }
    
    }
  }
  futureappts() {
    var appointments = this.state.futureappts;
    if (appointments != "undefined" && appointments != null) {
      // if ( == "success"){
        if (Object.keys(appointments).length > 0) {
          const apptDlist = appointments.map(
            (appts, Index) => {
              let sno = Index+1;
              var apptstatus = 'Pending';
              if(appts.paid_status =='booked'){
                var apptstatus = 'Accepted'
              }else  if(appts.paid_status =='paid'){
                var apptstatus = 'Completed'
              }
              return (
                <tr key={Index}>
				         <td>{sno}</td>
                 <td>{appts.appointment_id}</td>
                 <td>{moment(appts.appointment_date).format('MMM DD Y hh:mm A')}</td>
                 <td>
                  {appts.medical_condition}
                  </td>
                  <td>
                  {appts.name}
                  </td>
                
                  <td>
                  {appts.gender}
                  </td>
                 
                  <td>
                  {appts.phone}
                  </td>
                  <td>
                    {apptstatus}
                  </td>
                 
                </tr>
              );
            }
          );
          return apptDlist;
        }
      else {
        return (
          <tr>
            <td colSpan="9" className="v-align-nr text-center">
              No Result
            </td>
          </tr>
        );
      }
    
    }
  }
  handleGoBack = () => {
    this.props.history.goBack();
  }
  handleClick = (index) => {
    this.setState({ activetab: index });
  };
  Capitalize(str){
    return str.charAt(0).toUpperCase() + str.slice(1);
    }
    ShowFamilyDocuments=(documents)=>{
      this.setState({ShowFamilyDocuments:true,FamilyDocuments:documents});
    }
    downloadImage(url, name) {
			fetch(url)
			.then(resp => resp.blob())
			.then(blob => {
				const url = window.URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.href = url;
				link.download = name;
				document.body.appendChild(link);
				link.click();
				window.URL.revokeObjectURL(url);
			})
			.catch(() => alert('An error sorry'));
		}
  render() {
    const { activetab } = this.state;
    console.log(activetab,'activetab')
     if((this.state.userlistview.subscribed_plan_name!='')&&(this.state.userlistview.subscribed_plan_name!=null)){
        if(this.state.userlistview.subscribed_plan_name == "Free"){

         var plan_paid_amount = "$"+0;
        }else{
           var plan_paid_amount = "$"+this.state.userlistview.plan_paid_amount;
        }
    }else{
     var plan_paid_amount = "N/A";
    }

    if((this.state.userlistview.subscribed_plan_name!='')&&(this.state.userlistview.subscribed_plan_name!=null)){
        var subscribed_plan_name = this.state.userlistview.subscribed_plan_name;
    }else{
     var subscribed_plan_name = "N/A";
    }


    if((this.state.userlistview.transactionId!='')&&(this.state.userlistview.transactionId!=null)){
        var transactionId = this.state.userlistview.transactionId;
    }else{
     var transactionId = "N/A";
    }

    var mobileno = ((this.state.userlistview.mobileno === null)||((this.state.userlistview.mobileno === ''))) ?  this.state.userlistview.mobileno = 'N/A' : this.state.userlistview.mobileno;

    var lastname = ((this.state.userlistview.lastname === null)||(this.state.userlistview.lastname === "")) ?  this.state.userlistview.lastname = 'N/A' : this.state.userlistview.lastname;


    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="ongoingride" />  
      
      <div className="content"> 
        <div className="content-wrapper">
         <div class="content-wrapper-before"></div>
            <div className="content-body">
               <div className="form-wrapper leaa-admin-head card">
                 <div>
                  <span className='cursor-pointer btn-arrow' onClick={this.handleGoBack}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800.48 613.46"><g xmlns="http://www.w3.org/2000/svg" transform="matrix(-1 0 0 -1 800.48 613.46)"><g xmlns="http://www.w3.org/2000/svg" transform="matrix(0 -1 1 0 -199.77 906.72003)"><path d="m857.14 640.38-221.02 225.27-4.25-665.88-65.172 1.4141-1.418 667.3-223.85-226.68-48.172 48.172 306.02 310.27 307.44-311.69z" fillRule="evenodd"/></g></g></svg></span>
               </div> 
               <div className='d-flex w-100 gap-4 vertical-menu-content-wrapper mt-4 min-vh-100'>
                <ul className="vertical-menu-list">

                  <li onClick={() => this.handleClick('userdetail')} className={activetab === 'userdetail' ? 'active cursor-pointer' : ' cursor-pointer'}>
                    <svg width="16" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="5 3 22 26"><path d="M11,29H21a6,6,0,0,0,0-12H11a6,6,0,0,0,0,12Z"/><circle cx="16" cy="9" r="6"/></svg>
                    User Demographics</li>

                  <li onClick={() => this.handleClick('familylist')} className={activetab === 'familylist' ? 'active cursor-pointer' : ' cursor-pointer'}>
                    <svg width="16" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="5 3 22 26"><path d="M11,29H21a6,6,0,0,0,0-12H11a6,6,0,0,0,0,12Z"/><circle cx="16" cy="9" r="6"/></svg>
                    Family Members
                    <i>{this.state.familtlistview.length}</i>
                    </li>

                  <li onClick={() => this.handleClick('pastappointment')} className={activetab === 'pastappointment' ? 'active cursor-pointer' : ' cursor-pointer'}>
                    <svg width="16" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="7.81 7.81 84.38 84.37">  <g>   <path d="m7.8125 39.062v43.75c0 5.1719 4.2031 9.375 9.375 9.375h65.625c5.1719 0 9.375-4.2031 9.375-9.375v-43.75zm58.332 22.656-16.145 16.145c-2.2305 2.2305-5.8438 2.2305-8.0742 0l-8.0742-8.0742c-2.2305-2.2305-2.2305-5.8438 0-8.0742 2.2305-2.2305 5.8438-2.2305 8.0742 0l4.0352 4.0352 12.109-12.109c2.2305-2.2305 5.8438-2.2305 8.0742 0s2.2305 5.8438 0 8.0742z"></path>   <path d="m82.812 17.188h-3.125v-4.6875c0-2.5898-2.0977-4.6875-4.6875-4.6875s-4.6875 2.0977-4.6875 4.6875v4.6875h-40.625v-4.6875c0-2.5898-2.0977-4.6875-4.6875-4.6875s-4.6875 2.0977-4.6875 4.6875v4.6875h-3.125c-5.1719 0-9.375 4.2031-9.375 9.375v6.25h84.375v-6.25c0-5.1719-4.2031-9.375-9.375-9.375z"></path>  </g> </svg>
                    Past Appointments<i>{this.state.pastappointment.length}</i></li>

                    <li onClick={() => this.handleClick('ongoingappts')} className={activetab === 'ongoingappts' ? 'active cursor-pointer' : ' cursor-pointer'}>
                    <svg width="16" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="7.81 7.81 84.38 84.37">  <g>   <path d="m7.8125 39.062v43.75c0 5.1719 4.2031 9.375 9.375 9.375h65.625c5.1719 0 9.375-4.2031 9.375-9.375v-43.75zm58.332 22.656-16.145 16.145c-2.2305 2.2305-5.8438 2.2305-8.0742 0l-8.0742-8.0742c-2.2305-2.2305-2.2305-5.8438 0-8.0742 2.2305-2.2305 5.8438-2.2305 8.0742 0l4.0352 4.0352 12.109-12.109c2.2305-2.2305 5.8438-2.2305 8.0742 0s2.2305 5.8438 0 8.0742z"></path>   <path d="m82.812 17.188h-3.125v-4.6875c0-2.5898-2.0977-4.6875-4.6875-4.6875s-4.6875 2.0977-4.6875 4.6875v4.6875h-40.625v-4.6875c0-2.5898-2.0977-4.6875-4.6875-4.6875s-4.6875 2.0977-4.6875 4.6875v4.6875h-3.125c-5.1719 0-9.375 4.2031-9.375 9.375v6.25h84.375v-6.25c0-5.1719-4.2031-9.375-9.375-9.375z"></path>  </g> </svg>
                    Ongoing Appointments<i>{this.state.ongoingappts.length}</i></li>

                    <li onClick={() => this.handleClick('futureappts')} className={activetab === 'futureappts' ? 'active cursor-pointer' : ' cursor-pointer'}>
                    <svg width="16" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="7.81 7.81 84.38 84.37">  <g>   <path d="m7.8125 39.062v43.75c0 5.1719 4.2031 9.375 9.375 9.375h65.625c5.1719 0 9.375-4.2031 9.375-9.375v-43.75zm58.332 22.656-16.145 16.145c-2.2305 2.2305-5.8438 2.2305-8.0742 0l-8.0742-8.0742c-2.2305-2.2305-2.2305-5.8438 0-8.0742 2.2305-2.2305 5.8438-2.2305 8.0742 0l4.0352 4.0352 12.109-12.109c2.2305-2.2305 5.8438-2.2305 8.0742 0s2.2305 5.8438 0 8.0742z"></path>   <path d="m82.812 17.188h-3.125v-4.6875c0-2.5898-2.0977-4.6875-4.6875-4.6875s-4.6875 2.0977-4.6875 4.6875v4.6875h-40.625v-4.6875c0-2.5898-2.0977-4.6875-4.6875-4.6875s-4.6875 2.0977-4.6875 4.6875v4.6875h-3.125c-5.1719 0-9.375 4.2031-9.375 9.375v6.25h84.375v-6.25c0-5.1719-4.2031-9.375-9.375-9.375z"></path>  </g> </svg>
                    Future Appointments<i>{this.state.futureappts.length}</i></li>

                {/*   <li onClick={() => this.handleClick('medical_list')} className={activetab === 'medical_list' ? 'active cursor-pointer' : ' cursor-pointer'}>
                    <svg width="16" xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="77.38 31.95 345.25 436.1"><path clipRule="evenodd" d="M113.718,31.949c-20.079,0-36.343,16.264-36.343,36.34v363.421  c0,20.078,16.264,36.34,36.343,36.34h272.566c20.079,0,36.34-16.262,36.34-36.34V158.417c0-12.173-4.81-22.982-12.536-30.796  l-82.497-82.319c-8.173-8.265-19.628-13.352-32.164-13.352H113.718z M240.914,95.548c0-9.996,8.177-18.173,18.172-18.173  c9.991,0,18.172,8.177,18.172,18.173v68.14c0,7.542,6.087,13.629,13.626,13.629h68.143c9.99,0,18.172,8.175,18.172,18.17  c0,9.996-8.182,18.171-18.172,18.171h-81.769c-20.079,0-36.344-16.264-36.344-36.341V95.548z" fill="#000000" fillRule="evenodd"></path></svg>
                    Medical History</li> */}
                </ul>
                <div className="vertical-menu-content">

                      <div className={activetab === 'userdetail' ?"content-div userdetail active":"content-div userdetail"} id="userdetail">
                      <div className="title text-start">
                      <h3>User Demographics</h3>
                      </div>
                      <div className="form-row">
                        <div className="details_left">
                            <div className="view-details">
                              <div className="view-details-one">
                                  <p>Username<span>:</span></p>
                              </div>
                              <div className="view-details-two">
                                  <p>{this.state.userlistview.username}</p>
                              </div>
                            </div>
                            <div className="view-details">
                              <div className="view-details-one">
                                  <p>Firstname<span>:</span></p>
                              </div>
                              <div className="view-details-two">
                                  <p>{this.state.userlistview.firstname}</p>
                              </div>
                            </div>
                            <div className="view-details">
                              <div className="view-details-one">
                                  <p>Lastname<span>:</span></p>
                              </div>
                              <div className="view-details-two">
                                  <p>{lastname}</p>
                              </div>
                            </div>
                            <div className="view-details">
                              <div className="view-details-one">
                                  <p>Email<span>:</span></p>
                              </div>
                              <div className="view-details-two">
                                  <p>{this.state.userlistview.email}</p>
                              </div>
                            </div>
                            <div className="view-details">
                              <div className="view-details-one">
                                  <p>Mobile number<span>:</span></p>
                              </div>
                              <div className="view-details-two">
                                  <p>{mobileno}</p>
                              </div>
                              
                            </div>
                            <div className="view-details">
                              <div className="view-details-one">
                                  <p>User Type<span>:</span></p>
                              </div>
                              <div className="view-details-two">
                                  <p>{ this.state.userlistview.user_type && `${this.Capitalize(this.state.userlistview.user_type)}`}</p>
                              </div>
                            </div>

                            <div className="view-details">
                              <div className="view-details-one">
                                  <p>Location<span>:</span></p>
                              </div>
                            <div className="view-details-two">
                                  <p>{ this.state.userlistview.location}</p>
                              </div>
                            </div>

                            <div className="view-details">
                              <div className="view-details-one">
                                  <p>Plan type<span>:</span></p>
                              </div>
                              <div className="view-details-two">
                                  <p>{subscribed_plan_name}</p>
                              </div>
                              
                            </div>

                            {/* <div className="view-details">
                              <div className="view-details-one">
                                  <p>Paid Plan amount<span>:</span></p>
                              </div>
                              <div className="view-details-two">
                                  <p>{plan_paid_amount}</p>
                              </div>
                            </div> */}

                             {/*  <div className="view-details">
                              <div className="view-details-one">
                                  <p>Transaction ID<span>:</span></p>
                              </div>
                              <div className="view-details-two">
                                  <p>{transactionId}</p>
                              </div>
                              </div> */}
                              {(this.state.userlistview.admin_type!== null && this.state.userlistview.adminname!== undefined) && <div className="view-details">
                              <div className="view-details-one">
                                  <p>Status Changed by<span>:</span></p>
                              </div>
                              <div className="view-details-two">
                                  <p>{this.state.userlistview.adminname+' ('+this.state.userlistview.admin_type+')'}</p>
                              </div>
                              </div>}


                        </div>
                      </div>
                      </div>
                      <div className={activetab === 'familylist' ?"content-div familylist active":"content-div familylist"} id="familylist">
                      <div className="title text-start">
                        <h3>{this.state.ShowFamilyDocuments==false?'Family Members':'Medical Documents'}</h3>
                      </div>
                      {this.state.ShowFamilyDocuments==false?
                      <div className="car-listing-row">
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th>S.No</th>
                                <th>Name</th>
                                <th>Relationship</th>
                                <th>Age</th>
                                <th>DOB</th>
                                <th>Email</th>
                                <th>Mobile</th>
                                <th>document</th>
                              </tr>
                            </thead>
                            <tbody>{this.familylist()}
                            </tbody>
                          </Table>
                        </div>:
                           <>{this.state.FamilyDocuments.map(
                              (file, Index) => {                                
                                return (
                                  <div className="form-row EHR-view">
                                  <div className="details_left">                               
                                        <div className="view-details-two m-0">
                                            <p onClick={() => this.downloadImage(mediaUrl+"familymedical/"+file.image,file.image)} >   
                                              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="10.79 5 78.42 90"><g data-name="pdf"><path d="M87,25.34,70.92,7.75A8.46,8.46,0,0,0,64.69,5H19.23a8.45,8.45,0,0,0-8.44,8.44V86.56A8.45,8.45,0,0,0,19.23,95H80.77a8.45,8.45,0,0,0,8.44-8.44V31A8.4,8.4,0,0,0,87,25.34Zm-5.33,2.51H71.31A2.82,2.82,0,0,1,68.5,25V13.44Zm-.9,61.53H19.23a2.82,2.82,0,0,1-2.82-2.82V13.44a2.82,2.82,0,0,1,2.82-2.82H62.87V25a8.45,8.45,0,0,0,8.44,8.44H83.59V86.56A2.82,2.82,0,0,1,80.77,89.38Z"/><path d="M67.22,28.89,66.5,9H14.67V91.33H85.5V29.12ZM40.4,53.48a6.21,6.21,0,0,1-1.79,2.22A7.6,7.6,0,0,1,36,57a11.54,11.54,0,0,1-3.16.42H31.17a1,1,0,0,0-.94.94v5.24a.94.94,0,0,1-.94.94H26.44a.94.94,0,0,1-.94-.94V44.46a.94.94,0,0,1,.94-.94h6.27a14.69,14.69,0,0,1,3.26.34A7.33,7.33,0,0,1,38.61,45a5.54,5.54,0,0,1,1.79,2.09,7.06,7.06,0,0,1,.66,3.21A7.24,7.24,0,0,1,40.4,53.48Zm18.73,5.15A8.69,8.69,0,0,1,57,61.93a8.58,8.58,0,0,1-3.24,2,12.9,12.9,0,0,1-4.2.65H44.35a.94.94,0,0,1-.94-.94V44.46a.94.94,0,0,1,.94-.94h5a14.65,14.65,0,0,1,4.35.61A8.78,8.78,0,0,1,57,46a8.28,8.28,0,0,1,2.12,3.24,13.07,13.07,0,0,1,.75,4.67A13.36,13.36,0,0,1,59.13,58.63ZM75.82,46.58a.94.94,0,0,1-.94.94H68.26a.94.94,0,0,0-.94.94v3a.94.94,0,0,0,.94.94h5.4a.94.94,0,0,1,.94.94v2.12a.94.94,0,0,1-.94.94h-5.4a.94.94,0,0,0-.94.94v6.27a.94.94,0,0,1-.94.94H63.52a.94.94,0,0,1-.94-.94V44.46a.94.94,0,0,1,.94-.94H74.88a.94.94,0,0,1,.94.94Z"/><path d="M53.37,48.76a4.33,4.33,0,0,0-1.87-1.08,8.5,8.5,0,0,0-2.45-.32h-.9V60.69h.9a7.84,7.84,0,0,0,2.45-.35,4.65,4.65,0,0,0,1.87-1.14,5.6,5.6,0,0,0,1.21-2.08A10.08,10.08,0,0,0,55,53.93a9.8,9.8,0,0,0-.43-3.14A5.23,5.23,0,0,0,53.37,48.76Z"/><path d="M35.39,48a5.42,5.42,0,0,0-3-.68H31.17a1,1,0,0,0-.94.94V52.7a1,1,0,0,0,.94.94h1.38q3.87,0,3.87-3.35A2.55,2.55,0,0,0,35.39,48Z"/></g></svg> 
                                             {file.image} </p> 
                                        </div>
                                  </div>  
                                  {/* <div className='frame open'>
                                  <iframe  class="responsive-iframe"  src={mediaUrl+"familymedical/"+file.image}>       
                                  </iframe>
                                  </div> */}

                                  </div>
                                );
                              }
                            )}                          
                         </>
                         }
                        
                      </div>
                      <div className={activetab === 'pastappointment' ?"content-div appointments active":"content-div appointments"} id="appointments">
                      <div className="title text-start">
                        <h3>Past Appointment Lists</h3>
                      </div>
                      <div className="car-listing-row">
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th>S.No</th>
                                <th>Appt ID</th>
                                <th>Schedule On</th>
                                <th>Reason</th>
                                <th>Name</th>
                                <th>Gender</th>
                                <th>Mobile</th>
                                <th>Status</th>
                              </tr>
                            </thead>
                            <tbody> {this.pastappointment()}<br/>
                            
                            </tbody>
                          </Table>
                          {/* <div className="text-center">
                            <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={this.state.totalRecord}
                            pageRangeDisplayed={5}
                            onChange={this.handlePageChange.bind(this)}
                            />
                          </div> */}
                        </div>
                      </div>

                      <div className={activetab === 'ongoingappts' ?"content-div appointments active":"content-div appointments"} id="appointments">
                      <div className="title text-start">
                        <h3>Ongoing Appointments</h3>
                      </div>
                      <div className="car-listing-row">
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th>S.No</th>
                                <th>Appt ID</th>
                                <th>Schedule On</th>
                                <th>Reason</th>
                                <th>Name</th>
                                <th>Gender</th>
                                <th>Mobile</th>
                                <th>Status</th>
                              </tr>
                            </thead>
                            <tbody> {this.ongoingappts()}<br/>
                            
                            </tbody>
                          </Table>
                          {/* <div className="text-center">
                            <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={this.state.totalRecord}
                            pageRangeDisplayed={5}
                            onChange={this.handlePageChange.bind(this)}
                            />
                          </div> */}
                        </div>
                      </div>


                      <div className={activetab === 'futureappts' ?"content-div appointments active":"content-div appointments"} id="appointments">
                      <div className="title text-start">
                        <h3>Future Appointments</h3>
                      </div>
                      <div className="car-listing-row">
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th>S.No</th>
                                <th>Appt ID</th>
                                <th>Schedule On</th>
                                <th>Reason</th>
                                <th>Name</th>
                                <th>Gender</th>
                                <th>Mobile</th>
                                <th>Status</th>
                              </tr>
                            </thead>
                            <tbody> {this.futureappts()}<br/>
                            
                            </tbody>
                          </Table>
                          {/* <div className="text-center">
                            <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={this.state.totalRecord}
                            pageRangeDisplayed={5}
                            onChange={this.handlePageChange.bind(this)}
                            />
                          </div> */}
                        </div>
                      </div>
                   
                </div>
              </div>
             
                  
              </div>            
            
          </div>
         </div> 
        </div>        
    </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    userlistview: state.userlistview
    
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getViewUserList: (userid) =>{
       dispatch({ type: GET_VIEWUSERLIST,userid });
    },
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(ViewEHR));